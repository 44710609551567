<template>
    <div>
        <div class="acccess-application">
            <div class="main-option-for-app">
                <h1
                    :class="addClass('forms_title')"
                    class="mb-4"
                    >
                    <img src="../../assets/img/icon4.png"/> Заявка одобрена!
                </h1>

                <!--        <p v-if="this.anketa.creditInformation.approvedAmountDifference">Сумма заявки была изменена</p>-->
            </div>

            <v-snackbar
                :value="creditInfo.approvedAmountDifference"
                color="#26a212"
                timeout="6000"
                top
                >
                <p>
                    Сумма Вашей заявки была изменена. <br/>
                    Новая сумма {{ creditInfo.requestedSumm }} &#8381;
                </p>

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="creditInfo.approvedAmountDifference = false;
                                forceRerender();
                        "
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <div class="account-info-well">
                <table class="table-well">
                    <tr :class="$root.appType('travelata') ? 'travelata_access-application_table_item' : ''">
                        <td>Сумма заявки</td>
                        <td>
                            <b>{{ creditInfo.requestedSumm }} &#8381;</b>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>Размер займа</td>
                        <td>{{ creditInfo.amount }} &#8381;</td>
                    </tr> -->
                    <tr v-if="!$root.appType('travelata') && !$root.appType('southwind') && !$root.appType('bankiros')">
                        <td>Срок займа</td>
                        <td>{{ creditInfo.period }} мес.</td>
                    </tr>
                    <tr
                        v-if="creditInfo.currentDebtAmount != 0 && (!$root.appType('travelata') && !$root.appType('bankiros') && !$root.appType('southwind'))"
                        >
                        <td>Остаток по текущему займу</td>
                        <td>{{ creditInfo.currentDebtAmount }} &#8381;</td>
                    </tr>

                    <tr
                        v-if="creditInfo.currentDebtAmount != 0 && ($root.appType('travelata') || $root.appType('bankiros') || $root.appType('southwind'))"
                        class="travelata_access-application_table_item account-info-well_item"
                        >
                        <td>Текущее финансовое обязательство.
                            <v-tooltip v-if="$root.appType('bankiros')" top>
                                <template v-slot:activator="{ on, attrs }">
                                  <span class="travelata_link pointer" v-bind="attrs" v-on="on" >Подробнее</span>
                                </template>
                                <span class="tooltip-text text-center">Текущая задолженность ООО « МКК Кредит Лайн» {{creditInfo.currentDebtAmount}} ₽</span>
                            </v-tooltip>
                       </td>
                        <td>{{ creditInfo.currentDebtAmount }} &#8381;</td>
                    </tr>
                </table>
            </div>
            <div class="promocode_container mt-8">
                <v-form @submit.prevent ref="form">

                    <FieldsCard
                        ref="promocodeRef"
                        class="promocode_input"
                        :anketaNames="['promocode']"
                    />

                </v-form>
                <v-btn
                    v-if="anketa.promocode.promocodeOptions.sendRequest"
                    @click="sendPromoVerify"
                    fab
                    elevation="0"
                    class="promocode_button"
                    >
                    <img src="../../assets/img/promocode/promocode_arrow.svg">
                </v-btn>
                <v-btn
                    v-if="anketa.promocode.promocodeOptions.ok"
                    fab
                    elevation="0"

                    class="promocode_button"
                    >
                    <img src="../../assets/img/promocode/promocode_ok.svg">
                </v-btn>
                <v-btn
                    v-if="anketa.promocode.promocodeOptions.error"
                    @click="clearPromoField"
                    fab
                    elevation="0"

                    class="promocode_button"
                    >
                    <img src="../../assets/img/promocode/promocode_cross.svg">
                </v-btn>
            </div>
            <h2
                :class="$root.appType('travelata') ? 'travelata_second-title' : ''"
                class="mb-4"
                >График платежей

                <v-tooltip v-if="creditInfo.currentDebtAmount != 0 && ($root.appType('bankiros') || $root.appType('southwind') || $root.appType('travelata'))" right>
                    <template v-slot:activator="{ on, attrs }">
                        <img class="tooltip-icon" v-bind="attrs" v-on="on" src="@/assets/img/bankiros/tooltip.svg" alt="tooltip">
                    </template>
                    <span class="tooltip-text text-center">График платежей ООО «МКК Кредит Лайн»</span>
                </v-tooltip>
            </h2>
            <div class="table-schedule">
                <div class="table-block">
                    <p
                        :class="$root.appType('travelata') ? 'travelata_payment-list_header' : ''"
                        class="table-block-item"
                        >Дата платежа
                    </p>
                    <p
                        :class="$root.appType('travelata') ? 'travelata_payment-list_header' : ''"
                        class="table-block-item"
                        >Сумма
                    </p>
                </div>

                <div
                    v-for="(schedule) in creditInfo.paymentSchedule"
                    :key="schedule.date"
                    >
                    <div class="table-block">
                        <p
                            :class="$root.appType('travelata') ? 'travelata_payment-list_item' : ''"
                            class="table-block-item"
                            >{{ schedule.date | formatDate }}
                        </p>
                        <p
                            :class="$root.appType('travelata') ? 'travelata_payment-list_item' : ''"
                            class="table-block-item"
                            >- {{ schedule.summ }} &#8381;
                        </p>
                    </div>
                </div>
            </div>

            <AdditionalServices
                @choiseTarif="choosenTariff"
                @refusalService="choiseRefusalService"
                :loading="loading"
                :deleteInsurancePackage="deleteInsurancePackage"
            />

            <a
                :class="addClass('download-documents', false)"
                class="download-documents"
                :href="getLink"
                target="_blank"
                >Скачать договор
            </a>

            <a
                :class="addClass('download-documents', false)"
                class="download-documents"
                href="https://paylate.ru/o-servise/"
                target="_blank"
                >О сервисе
            </a>

            <div class="fixed-button">
                <v-btn
                    :class="$root.appType('vtb') ? 'vtb-button' : 'rectangle-button modal-button-accept'"
                    @click="sendRequestForSMSCode()"
                    >
                    <div v-if="this.anketa.promocode.promocodeOptions.ok === false">
                        Получить
                        <span class="ml-3">
                            {{ anketa.creditInformation.requestedSumm }}
                            <b class="ruble-icon">&#8381;</b>
                        </span>
                    </div>
<!--                    <div-->
<!--                        v-if="this.anketa.promocode.promocodeOptions.ok === true && !$root.appType('travelata')"-->
<!--                        class="promocode_next-step_button"-->
<!--                        >Подтвердить и получить-->
<!--                        <span class="ml-3">-->
<!--                            {{ anketa.creditInformation.requestedSumm }}-->
<!--                            <b class="ruble-icon">&#8381;</b>-->
<!--                        </span>-->
<!--                    </div>-->
                    <div
                        v-if="this.anketa.promocode.promocodeOptions.ok === true"
                        class="promocode_next-step_button"
                        >Получить
                        <span class="ml-3">
                            {{ anketa.creditInformation.requestedSumm }}
                            <b class="ruble-icon">&#8381;</b>
                        </span>
                    </div>
                </v-btn>
            </div>
            <p
                v-show="errorAuthTextApi.status"
                ref="errorInfo"
                class="error-text"
                >Ошибка {{ errorAuthTextApi.text }}
            </p>
        </div>
    </div>
</template>

<script>
import AdditionalServices from "@/components/AdditionalServices.vue";

import approved from "@/components/helpers/approved.json";

import FieldsCard from "@/components/FieldsCard"
import {
    moneyFormatWithRegExp,
    moneyFormatWithSpace,
    moneyRounding,
} from "@/components/helpers/MoneyFormat";

export default {
    components: {AdditionalServices,FieldsCard},
    name: "AccessApplication",
    data: () => ({
        openButtonMoreInsurance: false,
        componentKey: 0,
        loading: false,
        deleteInsurancePackage: false,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
        showPromocodeButton: true,
        exceptionsTypes: ['cash', 'auto','nordwind'],
        isShowFakeData: false
    }),

    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        windowWidth() {
            return this.$root.anketa.windowWidth;
        },

        creditInfo() {
            let credits = this.$root.anketa.creditInformation;

            if (Object.entries(credits).length !== 0) {
                for (const key in credits) {
                    if (Object.hasOwnProperty.call(credits, key)) {
                        let element = credits[key];
                        if (
                            (typeof element === "number" || typeof element === "string") &&
                            key !== "creditDocument"
                        ) {
                            credits[key] = moneyFormatWithSpace(credits[key]);
                        }
                    }
                }

                if (credits.paymentSchedule) {
                    credits.paymentSchedule.forEach((element) => {
                        element.summ = moneyFormatWithRegExp(element.summ, /\./, ",");
                    });
                    credits.insuranceData.forEach((element) => {
                        element.cost = moneyRounding(element.cost);
                    });

                    credits.period = credits.paymentSchedule.length;
                }
            }

            return credits;
        },

        isNordwind(){
            return this.$root.state.isNordwind()
        },

        promoFieldBorder(){
            let borderColor = null
            let promoError = this.anketa.promocode.promocodeOptions.error
            console.log('promocode error', promoError);
            if((this.$root.appType('cash') || this.$root.appType('auto')) && promoError){
                borderColor = '#EF4058'

            }
            else if(this.$root.appType('nordwind') && promoError) {
                borderColor = '#D6181E'

            }
            else if(this.$root.appType('vtb') && promoError) {
                borderColor = '#D6181E'
            }
            else if(this.$root.appType('travelata') && promoError) {
                borderColor = '#FA656E'
            }
            else {
                borderColor = '#E7E7E7'
            }
            return borderColor
        },

        promoFieldColor(){
            let borderColor = null
            let promoError = this.anketa.promocode.promocodeOptions.error
            if((this.$root.appType('cash') || this.$root.appType('auto')) && promoError){
                borderColor = '#EF4058'

            }
            else if(this.$root.appType('nordwind') && promoError) {
                borderColor = '#D6181E'

            }
            else if(this.$root.appType('vtb') && promoError) {
                borderColor = '#D6181E'
            }
            else {
                borderColor = '#0A0A0D'
            }
            return borderColor
        },

        getLink(){
            // let link = null
            console.log('AccessAplication modals', this)
            const url = location.hostname;
            let documents = null
            if(url === 'localhost' && this.isShowFakeData) {
                // documents = fakeData
            }else {
                documents = this.anketa.creditInformation.creditDocument
            }

            // documents = this.anketa.creditInformation.creditDocument

            // documents = this.anketa.creditInformation.creditDocument
            let document =  documents.find(el => el.documentName === 'consolidated')
            console.log('AccessApplication documents', documents)
            return document.url
        }
    },
    filters: {
        formatDate: (d) => d.substr(8, 2) + "." + d.substr(5, 2) + "." + d.substr(0, 4),
    },
    created() {
        if(Object.entries(this.$root.anketa.creditInformation).length === 0 && location.hostname === 'localhost') {
            this.$root.anketa.creditInformation = approved.info;
        }
    },
    mounted() {
        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("approved");
        this.$root.mindbox("Получил одобрение заявки");
        console.log('promocode anketa', this.anketa.promocode.promocodeOptions)
    },
    methods: {
        openNextStep(template) {
            let data = {
                prevPage: "accessApplication",
                nextPage: template,
            };

            this.$emit("changeTemplate", data);
        },
        choiseRefusalService() {
            this.deleteInsurancePackage = !this.deleteInsurancePackage;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        choosenTariff(id_tarif) {
            this.loading = true;
            this.sendInsurancePackage(id_tarif).then(() => {
                if (id_tarif !== 0) {
                    this.deleteInsurancePackage = false;
                    for (let k = 0; k < this.anketa.creditInformation.insuranceData.length; k++) {
                        if (this.anketa.creditInformation.insuranceData[k].id === id_tarif) {
                            this.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                        } else {
                            this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                        }
                    }
                } else {
                    for (let k = 0; k < this.anketa.creditInformation.insuranceData.length; k++) {
                        this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                    }
                }

                this.forceRerender();
                this.loading = false;
            })
        },
        sendInsurancePackage(id_tarif) {
            // return this.connector
            //     .getTokenAccess()
            //     .then((response) => {
            //         if (response) {
            //             localStorage.setItem("tokenAccess", response.access);
                        return this.connector
                            .isInsurancePackage(id_tarif)
                            .then((response) => {
                                console.log(response)
                                if (response.success) {
                                    this.anketa.creditInformation = response.info;
                                } else {
                                    return false;
                                }
                            })
                            .catch((error) => {
                                this.errorAuthTextApi.status = true;
                                this.errorAuthTextApi.text = error;
                            });
                    // } else {
                    //     this.errorAuthTextApi.status = true;
                    //     this.errorAuthTextApi.text = "";
                    // }
                // })
                // .catch((error) => {
                //     this.errorAuthTextApi.status = true;
                //     this.errorAuthTextApi.text = error;
                // });
        },

        sendRequestForSMSCode() {
            if(this.anketa.promocode.promocodeValue !== '' && this.anketa.promocode.promocodeOptions.ok){
                this.sendSavePromocode()
            }else if(this.anketa.promocode.promocodeValue === '' && !this.anketa.promocode.promocodeOptions.error) {
                this.callNextPage()
            }

        },

        callNextPage(){
            this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("get_money_approved");
            this.$root.mindbox("Ознакомился с условиями займа");
            this.openNextStep("account");
        },

        showPromocodeHandler(e){
            console.log(e.currentTarget)
            console.log('autofocus', this.anketa.promocode)
            if(e.currentTarget){
                this.anketa.promocode.onFocus = true
                this.showPromocodeButton = false
            }
            // else {
            //     this.promocodeOptions.showButton = true
            // }
        },
        sendPromoVerify(){
            this.connector.promoVerify(this.anketa.promocode.value, this.anketa.phone.valueFirstDigitIs9())
                .then((response) => {
                    console.log('promo_verify response', response)
                    if(response.success && response.message.reason) {
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.error = false
                        this.anketa.promocode.promocodeValue = this.anketa.promocode.value
                        this.anketa.promocode.onFocus = false
                        this.anketa.promocode.promocodeInfo = 'Промокод применён'
                        this.anketa.promocode.promocodeOptions.ok = true
                        this.anketa.promocode.value = ''
                        this.anketa.promocode.showPromocodeLogo = false
                        this.anketa.promocode.isDisabled = true
                        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("Promocode_Success");

                    }
                    else if(response.success && response.message.reason === false){
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.ok = false
                        this.anketa.promocode.onFocus = false
                        // this.promocodeInfo = 'Неверный промокод'
                        this.anketa.promocode.promocodeOptions.error = true
                        this.anketa.promocode.value = ''
                        this.anketa.promocode.showPromocodeLogo = false
                        this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("Promocode_error");
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                    this.scrollingToError()
                });
        },

        sendSavePromocode(){
            this.connector.promoSave(this.anketa.promocode.promocodeValue, this.anketa.phone.valueFirstDigitIs9())
                .then((response) => {
                    console.log('promo_save response', response)
                    if(response.success && response.message.reason) {
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.error = false
                        // this.anketa.promocode.onFocus = false
                        // this.anketa.promocodeInfo = 'Промокод применён'
                        this.anketa.promocode.promocodeOptions.ok = true
                        this.anketa.promocode.showPromocodeLogo = false

                        this.callNextPage()
                    }
                    else if(response.success && response.message.reason === false){
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.ok = false
                        // this.promocodeInfo = 'Неверный промокод'
                        this.anketa.promocode.promocodeOptions.error = true
                        this.anketa.promocode.showPromocodeLogo = false
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                    this.scrollingToError()
                });
        },

        scrollingToError(){
            const id = this.$refs.errorInfo
            setTimeout(() => {
                id.scrollIntoView({ behavior: "smooth"})

            }, 500);
        },

        clearPromoField(){
            this.anketa.promocode.showPromocodeLogo = true
            this.anketa.promocode.value = ''
            this.anketa.promocode.promocodeOptions.error = false
            this.showPromocodeButton = true
            this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("Promocode_error");
        },

        addClass(cssClass, useExeptionTypes = true){
            let searchingClass = null
            if(useExeptionTypes){
                if(this.exceptionsTypes.find(el => el === this.$root.state.type())) {
                    searchingClass = `${cssClass}`
                }else {
                    searchingClass = `${cssClass}_${this.$root.state.type()}`
                }
            }else {
                searchingClass = `${cssClass}_${this.$root.state.type()}`
            }
            return searchingClass
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/_travelata-variables.scss';

.promocode {

    &_container {
        position: relative;
    }

    &_input :deep(input){
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.09px;
        letter-spacing: -0.4px;
        //color: #0A0A0D;
        color: v-bind(promoFieldColor);
        border: 1px solid v-bind(promoFieldBorder);

        @media (max-width: 450px) {
            font-size: 16px;
            line-height: 18.75px;
        }
    }

    &_errors {
        border: 1px solid v-bind(promoFieldColor);
        color: v-bind(promoFieldBorder);
    }
    &_button {
        // width: 100%;
        position: absolute;
        top: 17px;
        right: 15px;
        width: 24px;
        height: 24px;

        & > img {
            width: 100%;
            height: 100%;

        }

    }

    &_fake-field-button {
        width: 100% !important;
        background-color: #fff !important;
        border: 1px solid #e7e7e7;
        border-radius: 12px;
        height: 56px !important;
    }

    &_next-step_button {
        @media (max-width: 450px) {
            font-size: 18px;
        }
        @media (max-width: 400px) {
            font-size: 16px;
        }
    }

    &_error {
        color: v-bind(promoFieldColor);
    }

}

.travelata {
    &_access-application {
        &_table {
            &_item {
                @include tr-text();
                font-family: 'OpenSansR';
                color: #707070;
            }
        }
    }

    &_link {
        color: #059BCE;
    }

    &_second-title {
        @include tr-text(20px, 600);
        font-family: 'OpenSansSB' !important;
        color: #333 !important;
    }
    &_payment-list {
        &_header {
            @include tr-text(14px, 400, 16px);
            color: #707070 !important;
            font-family: 'OpenSansR' !important;
        }
        &_item {
            @include tr-text();
            color: #707070 !important;
            font-family: 'OpenSansR' !important;
        }
    }

    &_download-documents {
        @include tr-text();
        font-family: 'OpenSansR' !important;
        color: #059BCE !important;
    }

    .v-tooltip__content {
        max-width: 195px;
        border-radius: 12px;
        padding: 6px 8px;
        font-size: 14px;
        text-align: center;
        background: rgba(100, 100, 100, 0.90) !important;

        span {
            text-align: center;
            letter-spacing: -0.4px;
        }
    }
}

.southwind {
    .v-tooltip__content {
        max-width: 195px;
        border-radius: 12px;
        padding: 6px 8px;
        font-size: 14px;
        text-align: center;
        background: rgba(100, 100, 100, 0.90) !important;

        span {
            text-align: center;
            letter-spacing: -0.4px;
        }
    }
}

.download-documents {
    &_cash, &_auto {
        color: #007AFF;
    }
    &_nordwind, &_southwind {
        color: #D6181E;
    }
    &_travelata {
        color: #059BCE;
    }

    &_vtb {
        color: #1976D2;
    }
    &_bankiros {
        color: #388EC3;
    }
}
</style>
